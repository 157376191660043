<template>
  <div class="container">
    <div class="card pb-0" style="position: relative">
      <el-form
        :inline="true"
        class="screen-form"
        :disabled="loading"
        @keyup.enter="getNumber"
      >
        <el-form-item label="姓名/手机号" class="pb-10">
          <el-input
            clearable
            v-model="form.name"
            placeholder="支持模糊搜索"
            style="width: 130px"
          ></el-input>
        </el-form-item>
        <el-form-item label="任职" class="pb-10">
          <el-select
            v-model="form.hospital"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="请选择医院"
            :remote-method="searchHospital"
            style="width: 240px"
          >
            <el-option
              v-for="item in config.hospital"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            clearable
            v-model="form.department"
            placeholder="请选择科室"
            style="width: 120px"
            class="ml-5"
          >
            <el-option
              v-for="item in config.department"
              :key="'department_' + item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分组" class="pb-10">
          <el-select
            clearable
            v-model="form.group"
            placeholder="请选择分组"
            style="width: 120px"
          >
            <el-option
              v-for="item in config.group"
              :key="'group_' + item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" class="pb-10">
          <el-select
            clearable
            v-model="form.state"
            fit-input-width
            placeholder="全部"
            style="width: 80px"
          >
            <el-option label="正常" value="1"></el-option>
            <el-option label="冻结" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="pb-10">
          <el-button type="primary" :loading="loading" @click="getNumber">
            <el-icon v-if="!loading"><search /></el-icon>
            <span>筛选</span>
          </el-button>
          <el-button @click="clean">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button class="add-btn" type="primary" @click="addDoctor">
        <el-icon><plus /></el-icon>
        <span>添加</span>
      </el-button>
    </div>
    <div class="card table-card no-select" v-loading="loading">
      <template v-if="!error">
        <el-table
          :data="list"
          style="width: 100%; border-radius: 8px"
          :row-style="{ cursor: 'pointer' }"
          @row-click="openDoctor"
          v-if="list.length > 0"
        >
          <el-table-column prop="id" label="编号" width="70" fixed />
          <el-table-column prop="name" label="姓名" width="80" fixed />
          <el-table-column prop="wid" label="工号" width="95" />
          <el-table-column prop="phone" label="手机号码" width="115" />
          <el-table-column prop="titleName" label="职称" width="100" />
          <el-table-column label="岗位" min-width="180">
            <template #default="scope">
              <span
                v-for="(item, index) in scope.row.post"
                :key="scope.row.id + '_post_' + index"
              >
                <span v-if="index !== 0">、</span>
                {{ item.name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="hospitalName" label="医院" min-width="200" />
          <el-table-column prop="departmentName" label="科室" min-width="80" />
          <el-table-column prop="groupName" label="分组" min-width="80" />
          <el-table-column prop="lastLogin" label="上次登录" width="160" />
          <el-table-column label="状态" align="center" width="75" fixed="right">
            <template #default="scope">
              <el-tag type="success" effect="dark" v-if="scope.row.state === 1"
                >正常</el-tag
              >
              <el-tag type="info" effect="dark" v-else>冻结</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="下线" align="center" width="60" fixed="right">
            <template #default="scope">
              <el-button
                type="text"
                style="color: red"
                @click.stop="offline(scope.row.id)"
                ><el-icon><switch-button /></el-icon
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-empty class="loading" v-else description="暂无符合的用户">
          <el-button @click="clean">重置筛选条件</el-button>
        </el-empty>
      </template>
      <el-result
        v-else
        icon="error"
        title="加载失败"
        style="padding-top: calc(50vh - 220px)"
      />
    </div>
    <div class="card mb-0" v-if="!loading && total > 0">
      <div class="page-number">每页{{ number }}条 / 共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="number"
        @current-change="getList"
      />
    </div>
    <user-drawer ref="userDrawer" @update="drawerUpdate" />
  </div>
</template>

<script>
import UserDrawer from "../../components/common/user-drawer.vue";
import { Search, Plus, SwitchButton } from "@element-plus/icons";
import { common, doctor } from "../../plugin/api";
import { date } from "../../plugin/util";

export default {
  name: "UserList",
  components: { UserDrawer, Search, Plus, SwitchButton },
  data: () => ({
    error: false,
    loading: true,
    list: [],
    form: {
      name: "",
      hospital: "",
      department: "",
      group: "",
      phone: "",
      state: "",
    },
    config: { title: [], department: [], group: [], post: [] },
    number: 13,
    total: 0,
    page: 0,
  }),
  methods: {
    init() {
      this.$refs.userDrawer.close();
      let height = document.body.clientHeight;
      if (height > 768) this.number = 14 + parseInt((height - 768) / 40);
      this.initCache();
      this.getNumber();
    },
    initCache() {
      for (let key in this.config) {
        let cache = localStorage.getItem("cache_" + key);
        if (cache) this.config[key] = JSON.parse(cache);
      }
      common.initPage("/doctor/list").then((res) => {
        if (res.state) {
          for (let key in res.data) {
            localStorage.setItem("cache_" + key, JSON.stringify(res.data[key]));
          }
          this.config = res.data;
        }
      });
    },
    getNumber() {
      this.loading = true;
      this.form.phone = this.form.name;
      doctor
        .getNumber(this.form)
        .then((res) => {
          this.total = res.data;
          if (this.total > 0) this.getList(1);
          else {
            this.list = [];
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
          this.total = 0;
        });
    },
    getList(page) {
      this.page = page;
      doctor
        .getList(this.form, page, this.number)
        .then((res) => {
          for (let i in res.data) {
            let lastLogin = res.data[i].lastLogin;
            if (lastLogin)
              res.data[i].lastLogin = date.format(
                lastLogin,
                "yyyy-mm-dd HH:MM:SS"
              );
          }
          this.list = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
    openDoctor(row) {
      this.$refs.userDrawer.open("look", row);
    },
    addDoctor() {
      this.$refs.userDrawer.open("add", 0);
    },
    clean() {
      this.form = {
        name: "",
        hospital: "",
        department: "",
        group: "",
        phone: "",
        state: "",
      };
      this.getNumber();
    },
    searchHospital(key) {
      common.searchHospital(key).then((res) => {
        setTimeout(() => {
          this.config.hospital = res.data;
        }, 500);
      });
    },
    drawerUpdate(model) {
      if (model === "edit") this.getList(this.page);
      else this.clean();
    },
    offline(id) {
      this.$confirm("强制下线后用户需要重新登录账户", "强制下线", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        doctor
          .offline(id)
          .then((res) => {
            if (res.state) {
              this.$message.success({
                message: "已经强制下线",
                center: true,
              });
            } else {
              this.showWarning("操作下线失败");
            }
          })
          .catch(() => {
            this.showWarning("操作下线失败");
          });
      });
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: {
      handler(to) {
        if (to.name === "UserList") this.init();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.screen-form .el-form-item {
  margin: 0 10px 0 0;
}

.page-number {
  align-items: center;
  font-size: 14px;
  display: flex;
  color: #999;
  height: 35px;
  float: right;
}

.table-card {
  min-height: calc(100vh - 200px);
}

.loading {
  margin-top: calc(50vh - 300px);
}

.add-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>

<template>
  <el-drawer
    custom-class="user-drawer no-select"
    v-model="show"
    :with-header="false"
    direction="rtl"
    :size="510"
    :before-close="closeDrawer"
  >
    <div v-loading="loading">
      <div class="drawer-head">
        <el-icon><user-filled /></el-icon>
        <span v-if="model === 'edit'">编辑信息</span>
        <span v-else-if="model === 'look'">用户信息</span>
        <span v-else>添加用户</span>
      </div>
      <el-form
        :disabled="model === 'look'"
        style="height: 390px"
        label-width="80px"
      >
        <el-form-item label="姓名" required>
          <el-input
            v-model="form.name"
            maxlength="6"
            clearable
            style="width: 130px"
          ></el-input>
          <div class="el-form-item__label ml-10">职称</div>
          <el-select
            v-model="form.title"
            clearable
            placeholder="请选择"
            style="width: 150px"
          >
            <el-option
              v-for="item in config.title"
              :key="'title_' + item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" required>
          <el-input
            v-model="form.phone"
            maxlength="11"
            placeholder="仅限11位数字"
            clearable
            style="width: 130px"
          ></el-input>
          <div class="el-form-item__label ml-10">工号</div>
          <el-input
            v-model="form.wid"
            maxlength="8"
            placeholder="最多8个字符"
            clearable
            style="width: 130px"
          ></el-input>
        </el-form-item>
        <el-form-item label="医院" required>
          <el-select
            v-model="form.hospital"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="请选择医院"
            :remote-method="searchHospital"
            style="width: 350px"
          >
            <el-option
              v-for="item in config.hospital"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="科室" required>
          <el-select
            v-model="form.department"
            placeholder="请选择科室"
            style="width: 130px"
          >
            <el-option
              v-for="item in config.department"
              :key="'department_' + item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <div class="el-form-item__label ml-10">分组</div>
          <el-select
            v-model="form.group"
            placeholder="请选择分组"
            clearable
            style="width: 130px"
          >
            <el-option
              v-for="item in config.group"
              :key="'group_' + item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="岗位" required>
          <el-select
            v-model="form.post"
            placeholder="请选择"
            multiple
            style="width: 330px"
          >
            <el-option
              v-for="item in config.post"
              :key="'post_' + item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录密码" :required="model === 'add'">
          <el-input
            v-model="form.password"
            clearable
            style="width: 200px"
          ></el-input>
          <div class="el-form-item__label ml-10">冻结</div>
          <el-switch v-model="form.frozen"></el-switch>
        </el-form-item>
        <el-form-item label="OPO授权">
          <el-input
            v-model="form.code"
            placeholder="请输入OPO系统账户授权码"
            clearable
            style="width: 250px"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-collapse class="authority-panel" v-model="authority.select" accordion>
        <el-collapse-item title="业务权限" name="1">
          <el-scrollbar height="calc(100vh - 604px)">
            <div class="sub-title">患者</div>
            <el-form :disabled="model === 'look'" label-width="80px">
              <el-form-item label="查看范围:">
                <el-radio-group v-model="authority.patient.range">
                  <el-radio label="1">本院</el-radio>
                  <el-radio label="2">本科室</el-radio>
                  <el-radio label="3">本小组</el-radio>
                  <el-radio label="4">仅自己主刀或主管</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="审核患者:">
                <el-radio-group v-model="authority.patient.examine">
                  <el-radio label="1">允许</el-radio>
                  <el-radio label="2">禁止</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="移交患者:">
                <el-radio-group v-model="authority.patient.transfer">
                  <el-radio label="1">任何时候都允许</el-radio>
                  <el-radio label="2">通过审核前允许</el-radio>
                  <el-radio label="3">禁止</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="脱敏等级:">
                <el-select
                  v-model="authority.patient.desensitization"
                  style="width: 250px"
                >
                  <el-option
                    label="4级 - 姓名、证件、地址和手机号"
                    value="1"
                  ></el-option>
                  <el-option
                    label="3级 - 姓名、证件和地址"
                    value="2"
                  ></el-option>
                  <el-option label="2级 - 姓名和证件" value="3"></el-option>
                  <el-option label="1级 - 证件信息" value="4"></el-option>
                  <el-option label="不脱敏" value="5"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="sub-title">随访</div>
            <el-form :disabled="model === 'look'" label-width="80px">
              <el-form-item label="随访反馈:">
                <el-radio-group v-model="authority.follow.info">
                  <el-radio label="1">允许操作</el-radio>
                  <el-radio label="2">禁止操作</el-radio>
                  <el-radio label="3">禁止查看</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="编辑计划:">
                <el-radio-group v-model="authority.follow.plan">
                  <el-radio label="1">允许</el-radio>
                  <el-radio label="2">禁止</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <div class="sub-title">留言咨询</div>
            <el-form :disabled="model === 'look'" label-width="80px">
              <el-form-item label="会话:">
                <el-radio-group v-model="authority.chat.session">
                  <el-radio label="1">允许操作</el-radio>
                  <el-radio label="2">禁止操作</el-radio>
                  <el-radio label="3">禁止查看</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </el-collapse-item>
        <el-collapse-item title="管理权限" name="2">
          <el-scrollbar height="calc(100vh - 604px)">
            <el-form :disabled="model === 'look'" label-width="80px">
              <el-form-item label="用户管理:">
                <el-radio-group v-model="authority.admin.user">
                  <el-radio label="1">允许添加和编辑</el-radio>
                  <el-radio label="2">允许编辑</el-radio>
                  <el-radio label="3">禁止操作</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="医院管理:">
                <el-radio-group v-model="authority.admin.hospital">
                  <el-radio label="1">允许添加和编辑</el-radio>
                  <el-radio label="2">允许编辑</el-radio>
                  <el-radio label="3">禁止操作</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="模板管理:">
                <el-radio-group v-model="authority.admin.template">
                  <el-radio label="1">允许添加和编辑</el-radio>
                  <el-radio label="2">允许编辑</el-radio>
                  <el-radio label="3">禁止操作</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="操作日志:">
                <el-radio-group v-model="authority.admin.log">
                  <el-radio label="1">全部</el-radio>
                  <el-radio label="2">本院</el-radio>
                  <el-radio label="3">本科室</el-radio>
                  <el-radio label="4">本组</el-radio>
                  <el-radio label="5">仅自己</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="系统设置:">
                <el-radio-group v-model="authority.admin.system">
                  <el-radio label="1">允许编辑</el-radio>
                  <el-radio label="2">禁止编辑</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </el-collapse-item>
      </el-collapse>
      <div class="mt-15">
        <el-button @click="closeDrawer">关闭</el-button>
        <el-button type="danger" @click="offline" v-if="model !== 'add'"
          >强制下线</el-button
        >
        <div class="float-right" v-if="model === 'edit'">
          <el-button @click="switchEdit(false)">取消</el-button>
          <el-button type="primary" style="width: 100px" @click="save"
            >提交更新</el-button
          >
        </div>
        <el-button
          v-else-if="model === 'look'"
          class="float-right"
          type="primary"
          style="width: 100px"
          @click="switchEdit(true)"
          >编辑信息</el-button
        >
        <el-button
          v-else
          class="float-right"
          type="primary"
          style="width: 100px"
          @click="save"
          >完成添加</el-button
        >
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { UserFilled } from "@element-plus/icons";
import { common, doctor } from "../../plugin/api";

export default {
  name: "UserDrawer",
  components: { UserFilled },
  emits: ["update"],
  data: () => ({
    show: false,
    model: "add",
    loading: false,
    form: {
      id: 0,
      name: "",
      wid: "",
      phone: "",
      title: "",
      hospital: "",
      hospitalName: "",
      department: "",
      group: "",
      post: "",
      password: "",
      code: "",
      frozen: false,
    },
    cache: "",
    authority: {
      select: "1",
      patient: {
        range: "4",
        examine: "1",
        transfer: "2",
        desensitization: "4",
      },
      follow: {
        info: "1",
        plan: "1",
      },
      chat: {
        session: "1",
      },
      admin: {
        user: "3",
        hospital: "3",
        template: "3",
        log: "5",
        system: "2",
      },
    },
    config: { title: [], department: [], group: [], post: [] },
  }),
  methods: {
    open(model, data) {
      for (let key in this.config) {
        let cache = localStorage.getItem("cache_" + key);
        if (cache) this.config[key] = JSON.parse(cache);
      }

      let postIds = [];
      for (let i in data.post) postIds.push(data.post[i].pid);
      this.config.hospital = [{id:data.hospital,name:data.hospitalName}]
      this.form = {
        id: data.id,
        name: data.name,
        wid: data.wid,
        phone: data.phone,
        title: data.title,
        hospital: data.hospital,
        department: data.department,
        group: data.group,
        post: postIds,
        password: "",
        code: "",
        frozen: data.state === 2,
      };

      if (model === "add") {
        this.authority = {
          select: "1",
          patient: {
            range: "4",
            examine: "1",
            transfer: "2",
            desensitization: "4",
          },
          follow: {
            info: "1",
            plan: "1",
          },
          chat: {
            session: "1",
          },
          admin: {
            user: "3",
            hospital: "3",
            template: "3",
            log: "5",
            system: "2",
          },
        };
      } else {
        let auth = data.authority;
        this.authority = {
          select: "1",
          patient: {
            range: auth.patient.substring(0, 1),
            examine: auth.patient.substring(1, 2),
            transfer: auth.patient.substring(2, 3),
            desensitization: auth.patient.substring(3, 4),
          },
          follow: {
            info: auth.follow.substring(0, 1),
            plan: auth.follow.substring(1, 2),
          },
          chat: {
            session: auth.chat,
          },
          admin: {
            user: auth.admin.substring(0, 1),
            hospital: auth.admin.substring(1, 2),
            template: auth.admin.substring(2, 3),
            log: auth.admin.substring(3, 4),
            system: auth.admin.substring(4, 5),
          },
        };
      }

      this.model = model;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    closeDrawer() {
      if (this.model === "look") this.show = false;
      else {
        this.$confirm("关闭窗口后未保存的信息将会丢失", "关闭窗口", {
          confirmButtonText: "确认关闭",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.show = false;
          });
      }
    },
    switchEdit(state) {
      if (state) {
        this.model = "edit";
        this.cache = JSON.stringify({
          form: this.form,
          auth: this.authority,
        });
      } else {
        this.$confirm("取消编辑不会保存已修改信息", "取消编辑", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.model = "look";
            this.form = JSON.parse(this.cache.form);
            this.authority = JSON.parse(this.cache.auth);
          });
      }
    },
    showWarning(msg) {
      this.$message.warning({
        message: msg,
        center: true,
      });
      return false;
    },
    checkRequired(form,isNull) {
      if (form.name === "") return this.showWarning("姓名不能为空");
      if (form.phone === "") return this.showWarning("手机号码不能为空");
      if (form.hospital === "") return this.showWarning("医院不能为空");
      if (form.department === "") return this.showWarning("科室不能为空");
      if(isNull){
        if (form.name === undefined) return this.showWarning("姓名不能为空");
        if (form.phone === undefined) return this.showWarning("手机号码不能为空");
        if (form.hospital === undefined) return this.showWarning("医院不能为空");
        if (form.department === undefined) return this.showWarning("科室不能为空");
      }
      return true;
    },
    getAuthorityCode() {
      let map = {};
      for (let key in this.authority) {
        if (key === "select") continue;
        let code = "";
        for (let sub in this.authority[key]) {
          code += this.authority[key][sub];
        }
        map[key] = code;
      }
      return map;
    },
    save() {
      if (this.model === "edit") {
        let update = {};
        let form = this.form;
        let auth = this.authority;
        if (!this.checkRequired(form,false)) return false;
        let old = JSON.parse(this.cache);
        for (let x in form) {
          if (x === "post" && JSON.stringify(form[x]) != JSON.stringify(old.form[x]))
            update[x] = form[x];
          else if (x !== "post" && form[x] !== old.form[x]) update[x] = form[x];
        }

        let authority = false;
        for (let x in auth) {
          if (x !== "select" && JSON.stringify(auth[x]) != JSON.stringify(old.auth[x])) authority = true;
        }
        if(authority) update.authority = JSON.stringify(this.getAuthorityCode());
        
        if (Object.keys(update).length === 0)
          return this.showWarning("用户信息没有变动");
        update.id = form.id;

        this.$confirm("您对用户信息进行了编辑,请确认无误再提交", "提交更新", {
          confirmButtonText: "确认提交",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            doctor
              .update(update)
              .then((res) => {
                if (res.state) {
                  this.cache = "";
                  this.model = "look";
                  this.form.password = "";
                  this.$message.success({
                    message: "编辑成功",
                    center: true,
                  });
                  this.$emit("update", "edit");
                } else {
                  this.showWarning(
                    res.message ? res.message : "发生意料之外的错误"
                  );
                }
              })
              .catch(() => {
                console.log("失败");
              });
          });
      } else {
        let form = this.form;
        if (!this.checkRequired(form,true)) return false;
        form.authority = JSON.stringify(this.getAuthorityCode());
        this.$confirm("您正在添加新的用户,请确认无误后提交", "添加用户", {
          confirmButtonText: "确认提交",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            doctor
              .add(form)
              .then((res) => {
                if (res.state) {
                  this.cache = "";
                  this.model = "look";
                  this.form.password = "";
                  this.$message.success({
                    message: "添加成功",
                    center: true,
                  });
                  this.$emit("update", "add");
                } else {
                  this.showWarning(
                    res.message ? res.message : "发生意料之外的错误"
                  );
                }
              })
              .catch(() => {
                console.log("添加失败");
              });
          });
      }
    },
    searchHospital(key) {
      common
        .searchHospital(key)
        .then((res) => {
          setTimeout(() => {
            this.config.hospital = res.data;
          }, 500);
        });
    },
    offline() {
      this.$confirm("强制下线后用户需要重新登录账户", "强制下线", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          doctor
            .offline(this.form.id)
            .then((res) => {
              if (res.state) {
                this.$message.success({
                  message: "已经强制下线",
                  center: true,
                });
              } else {
                this.showWarning("操作下线失败");
              }
            })
            .catch(() => {
              this.showWarning("操作下线失败");
            });
        });
    },
  },
};
</script>

<style scoped>
.drawer-head {
  margin-bottom: 10px;
  align-items: center;
  font-weight: bold;
  color: #516161;
  font-size: 18px;
  display: flex;
}

.drawer-head i {
  margin-right: 5px;
  font-size: 26px;
}

.authority-panel {
  height: calc(100vh - 540px);
}

.sub-title {
  font-weight: bold;
  font-size: 14px;
}

.authority-panel .el-form-item {
  margin-bottom: 5px;
}
</style>

<style>
.user-drawer {
  height: calc(100% - 25px) !important;
  margin: 10px 10px 0 0;
  border-radius: 8px;
  width: 400px;
}

.authority-panel .el-collapse-item__header {
  font-weight: normal;
  color: #606266;
  height: 31px;
}

.authority-panel .el-collapse-item__content {
  padding-bottom: 0;
}

.authority-panel .el-radio {
  margin-right: 15px;
}
</style>